import { Component } from '@angular/core';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { ConfigManagerProperties } from './shared/enums/config-manager-properties.enum';
import { XpoLtlAuthenticationService } from '@xpo-ltl/ngx-auth';
import { XpoLtlConditioningService } from './shared/services/conditioning-service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  build: string;

  constructor(
    private configManagerService: ConfigManagerService,
    private authService: XpoLtlAuthenticationService,
    private conditionService: XpoLtlConditioningService
  ) {
    const region = this.configManagerService.getSetting<string>(ConfigManagerProperties.region);
    this.authService.initAuthSetup$(region).subscribe();
    const prod = this.configManagerService.getSetting<boolean>(ConfigManagerProperties.production);
    if (prod) {
      this.build = this.configManagerService.getSetting<string>(ConfigManagerProperties.buildVersion);
    } else {
      this.build = `${this.configManagerService.getSetting<string>(ConfigManagerProperties.buildVersion)} - ${region}`;
    }
  }

  notifyTabSwitched(isProLookup: boolean): void {
    this.conditionService.tabSwitchedSubject$.next(isProLookup);
  }
}
