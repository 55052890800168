<mat-drawer-container style="flex: 1" #standardTray hasBackdrop="false">
  <mat-drawer #matDrawerTray xpoTrayDrawer mode="over" position="end">
    <xpo-tray-panel>
      <xpo-tray-panel-header>
        <xpo-tray-panel-header-title>
          <span>Customer Search</span>
        </xpo-tray-panel-header-title>
      </xpo-tray-panel-header>
      <xpo-tray-panel-content>
        <mat-accordion>
          <mat-expansion-panel (afterExpand)="matSearchAfterExpand()" [expanded]="!trayBtnClear">
            <mat-expansion-panel-header>
              <mat-panel-title>Search</mat-panel-title>
            </mat-expansion-panel-header>
            <form [formGroup]="trayForm">
              <div class="ui-TraySample-row">
                <mat-form-field floatLabel="always">
                  <mat-label>MAD Code :</mat-label>
                  <input
                    [matTooltip]="getErrorMessage('madCode')"
                    matTooltipPosition="above"
                    matInput
                    placeholder="Enter MAD Code"
                    formControlName="madCode"
                    type="string"
                  />
                </mat-form-field>
              </div>

              <div class="addresses" formGroupName="addresses">
                <div class="ui-TraySample-row">
                  <mat-form-field floatLabel="always">
                    <mat-label>Name :</mat-label>
                    <input
                      [matTooltip]="getErrorMessage('name1', 'addresses')"
                      matTooltipPosition="above"
                      matInput
                      placeholder="Enter Name"
                      formControlName="name1"
                      type="string"
                    />
                  </mat-form-field>

                  <mat-form-field floatLabel="always">
                    <mat-label>Name 2 :</mat-label>
                    <input
                      [matTooltip]="getErrorMessage('name2', 'addresses')"
                      matTooltipPosition="above"
                      matInput
                      placeholder="Enter Name 2"
                      formControlName="name2"
                      type="string"
                    />
                  </mat-form-field>
                </div>

                <div class="ui-TraySample-row">
                  <mat-form-field style="width: 375px" floatLabel="always">
                    <mat-label>Address :</mat-label>
                    <input
                      [matTooltip]="getErrorMessage('address', 'addresses')"
                      matTooltipPosition="above"
                      matInput
                      placeholder="Enter Address"
                      formControlName="address"
                      type="string"
                    />
                  </mat-form-field>
                </div>

                <div class="ui-TraySample-row">
                  <mat-form-field floatLabel="always">
                    <mat-label>City :</mat-label>
                    <input
                      [matTooltip]="getErrorMessage('cityName', 'addresses')"
                      matTooltipPosition="above"
                      matInput
                      placeholder="Enter City"
                      formControlName="cityName"
                      type="string"
                    />
                  </mat-form-field>

                  <mat-form-field floatLabel="always">
                    <mat-label>State :</mat-label>
                    <mat-select
                      [value]="trayForm.get('addresses')?.get('stateCd')?.value"
                      (keydown.enter)="preventSearch($event)"
                      formControlName="stateCd"
                    >
                      <mat-option *ngFor="let state of states$ | async" [value]="state.abbreviation">
                        {{ state.abbreviation }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <mat-form-field floatLabel="always">
                    <mat-label>Postal Code :</mat-label>
                    <input matInput placeholder="Enter Postal Code" formControlName="zipCd" type="string" />
                  </mat-form-field>

                  <mat-form-field floatLabel="always">
                    <mat-label>Country :</mat-label>
                    <mat-select
                      formControlName="countryCd"
                      [value]="trayForm.get('addresses')?.get('countryCd')?.value"
                      (selectionChange)="getStates($event)"
                    >
                      <mat-option *ngFor="let country of countryList" [value]="country">{{ country }}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
              <div class="ui-TraySample-content-btn">
                <button (click)="searchCustomer()" mat-flat-button xpoButton>Search</button>
                <div class="busyContainer" *ngIf="trayLoading">
                  <div [style.width.px]="40">
                    <xpo-busy-loader></xpo-busy-loader>
                  </div>
                </div>
                <!--                <button *ngIf="trayBtnClear" (click)="trayClear()" mat-flat-button xpoButton>Clear</button>-->
              </div>
            </form>
          </mat-expansion-panel>

          <mat-expansion-panel [expanded]="trayBtnClear">
            <mat-expansion-panel-header>
              <mat-panel-title>Result</mat-panel-title>
            </mat-expansion-panel-header>
            <div class="grid-container">
              <div *ngIf="(trayData$ | async)?.length == 0">No data found</div>
              <xpo-ag-grid *ngIf="(trayData$ | async)?.length > 0">
                <ag-grid-angular
                  [frameworkComponents]="frameworkComponents"
                  (gridReady)="trayOnGridReady($event)"
                  [columnDefs]="trayColumnDefs"
                  [gridOptions]="trayGridOptions"
                  [rowHeight]="25"
                  [headerHeight]="30"
                  (firstDataRendered)="onFirstDataRendered($event)"
                  [alwaysShowHorizontalScroll]="true"
                  rowSelection="single"
                  [rowData]="trayData$ | async"
                >
                </ag-grid-angular>
              </xpo-ag-grid>
            </div>
            <br />
            <xpo-button-group *ngIf="trayBtnClear">
              <button
                *ngIf="(trayData$ | async)?.length > 0"
                [disabled]="trayApiGrid?.getSelectedRows()?.length === 0"
                (click)="confirmCustomer(standardTray, trayApiGrid?.getSelectedRows())"
                mat-flat-button
                xpoButton
              >
                Confirm
              </button>
            </xpo-button-group>
          </mat-expansion-panel>
        </mat-accordion>
      </xpo-tray-panel-content>
    </xpo-tray-panel>
  </mat-drawer>
  <mat-drawer-content style="overflow: hidden">
    <xpo-card>
      <xpo-card-content>
        <form [formGroup]="cisReviewQueueForm">
          <div class="container-form">
            <div class="container-row">
              <mat-form-field floatLabel="always">
                <mat-label>SVC Center :</mat-label>
                <input
                  maxlength="3"
                  pattern="^[a-zA-Z]+$"
                  matInput
                  placeholder="Enter SIC"
                  formControlName="sicCode"
                  required
                  type="string"
                  (keyup.enter)="findUnmatched()"
                />
                <mat-error *ngIf="cisReviewQueueForm.get('sicCode').errors?.minLength">
                  SIC code requires 3 letters
                </mat-error>
                <mat-error *ngIf="cisReviewQueueForm.get('sicCode').errors?.pattern">
                  Only letters are allowed
                </mat-error>
              </mat-form-field>
              <mat-form-field floatLabel="always">
                <mat-label>Reviewer :</mat-label>
                <input readonly matInput placeholder="Enter Review" formControlName="review" required type="string" />
              </mat-form-field>
              <mat-form-field *ngIf="showInfo" floatLabel="always">
                <mat-label>Queue count :</mat-label>
                <input readonly matInput placeholder="Queue count" formControlName="count" type="string" />
              </mat-form-field>
            </div>
          </div>
        </form>
        <xpo-button-group>
          <button
            mat-flat-button
            xpoButton
            [disabled]="cisReviewQueueForm.invalid || showInfo"
            (click)="findUnmatched()"
          >
            Submit
          </button>
          <button mat-stroked-button xpoButton [disabled]="showInfo" (click)="clear()">Clear</button>
        </xpo-button-group>
      </xpo-card-content>
    </xpo-card>
    <br />
    <div class="busyContainer" *ngIf="loading">
      <div [style.width.px]="100">
        <xpo-busy-loader></xpo-busy-loader>
      </div>
    </div>
    <app-unmatched-customer
      [unmatchedCustomer]="unMatchedCustomer"
      (onSearchCustomer)="openTray($event, standardTray)"
      (onHandleUnMatchedCustomer)="handleUnMatchedCustomer($event)"
      (onUnassign)="unassignUser(standardTray)"
      *ngIf="showInfo"
    >
    </app-unmatched-customer>
  </mat-drawer-content>
</mat-drawer-container>
