import { Pipe, PipeTransform } from '@angular/core';
import { startCase as _startCase } from 'lodash';

@Pipe({
  name: 'humanize',
})
export class HumanizePipe implements PipeTransform {
  transform(value: any): any {
    if (typeof value !== 'string' || value === '') {
      return value;
    }
    return _startCase(value);
  }
}
