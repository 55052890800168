import { Component, OnInit } from '@angular/core';
import { CustomerApiService } from '@xpo-ltl-2.0/sdk-customer';
import { FormControl, FormGroup } from '@angular/forms';
import { XpoSnackBar } from '@xpo-ltl/ngx-ltl-core/snack-bar';
import {
  AsMatchedParty,
  GetItemPath,
  GetItemResp,
  GetShipmentQuery,
  Item,
  ItemQueueDetail,
  ListItemsByApplicationIdQuery,
  ShipmentApiService,
} from '@xpo-ltl-2.0/sdk-shipment';
import { ShipmentDetailCd, User } from '@xpo-ltl/sdk-common';
import { catchError, map, switchMap } from 'rxjs/operators';
import { combineLatest, forkJoin, Observable, of, throwError } from 'rxjs';
import { UnmatchedCustomer } from '../shared/models/unmatched-customer.model';
import {
  GetEmployeeDetailsByEmpIdPath,
  GetEmployeeDetailsByEmpIdQuery,
  HumanResourceApiService,
} from '@xpo-ltl/sdk-humanresource';
import { XpoLtlConditioningService } from '../shared/services/conditioning-service';

@Component({
  selector: 'app-prolookup',
  templateUrl: './prolookup.component.html',
  styleUrls: ['./prolookup.component.scss'],
})
export class ProlookupComponent implements OnInit {
  pro_lookupForm = new FormGroup({
    pro_number: new FormControl(''),
  });
  private currentUser: User;
  showInfo: boolean = false;
  loading: boolean = false;
  data: UnmatchedCustomer[] = [];

  constructor(
    private customerApiService: CustomerApiService,
    private snackbar: XpoSnackBar,
    private shipmentApiService: ShipmentApiService,
    private humanResourceApiService: HumanResourceApiService,
    private conditioningService: XpoLtlConditioningService
  ) {
    this.customerApiService.loggedInUser().subscribe((userData) => {
      this.currentUser = userData;
    });
  }

  ngOnInit(): void {}

  item$(itemQueueDetail: ItemQueueDetail, asMatchedParty: AsMatchedParty[]): Observable<UnmatchedCustomer> {
    const { item, queue } = itemQueueDetail;
    const unMatchedCustomer = new UnmatchedCustomer();
    const getItemPathParams = new GetItemPath();
    getItemPathParams.itemInstId = item.itemInstId;
    unMatchedCustomer.status = item.currentStatusCd;
    unMatchedCustomer.proNbr = item.applicationInstId;
    unMatchedCustomer.assigneeEmployeeId = item.assigneeEmployeeId;
    unMatchedCustomer.assigneeEmployeeName = '-//-';
    unMatchedCustomer.lockExpirationDateTime = item.lockExpirationDateTime;
    unMatchedCustomer.queueName = queue.queueName;
    return this.shipmentApiService.getItem(getItemPathParams).pipe(
      map((data: GetItemResp) => {
        unMatchedCustomer.determineCustomerType(data.item.applicationKey1);
        unMatchedCustomer.debtorInfo = data.item.applicationKey2;
        unMatchedCustomer.determineCustomerInfo(asMatchedParty);
        return unMatchedCustomer;
      }),
      switchMap((unMatchedCustomer) => {
        if (unMatchedCustomer.assigneeEmployeeId) {
          const pathParams = new GetEmployeeDetailsByEmpIdPath();
          const queryParams = new GetEmployeeDetailsByEmpIdQuery();
          pathParams.employeeId = unMatchedCustomer.assigneeEmployeeId;

          return this.humanResourceApiService.getEmployeeDetailsByEmpId(pathParams, queryParams).pipe(
            map((value) => {
              const { basicInfo } = value.employee;
              unMatchedCustomer.assigneeEmployeeName = `${basicInfo.firstName} ${basicInfo.lastName}`;
              return unMatchedCustomer;
            })
          );
        }
        return of(unMatchedCustomer);
      }),
      catchError((err) => {
        this.loading = false;
        this.snackbar.open({
          message: `Unable to Find Unmatched Customers.`,
          status: 'error',
          matConfig: {
            duration: 7000,
          },
        });
        return throwError(err);
      })
    );
  }

  onSearch() {
    this.loading = true;
    const pro_number = this.conditioningService.conditionProNumber(this.pro_lookupForm.get('pro_number').value);

    const getShipmentQueryParams = new GetShipmentQuery();
    getShipmentQueryParams.proNbr = pro_number;
    getShipmentQueryParams.shipmentDetailCd = [ShipmentDetailCd.SHIPMENT_PARTIES];
    this.shipmentApiService
      .getShipment(getShipmentQueryParams)
      .pipe(
        map((value) => {
          return value.asMatchedParty;
        }),
        switchMap((asMatchedParty) => {
          const queryParams = new ListItemsByApplicationIdQuery();
          queryParams.applicationInstId = pro_number.trim();
          return this.shipmentApiService.listItemsByApplicationId(queryParams).pipe(
            map((data) => {
              if (data.items.length == 0) {
                throw {
                  error: {
                    message: 'Pro Number not found.',
                  },
                };
              }
              return data.items;
            }),
            map((items) => {
              return { items, asMatchedParty };
            })
          );
        }),
        switchMap(({ items, asMatchedParty }) => {
          return combineLatest(
            items.map((item) => {
              return this.item$(item, asMatchedParty);
            })
          );
        }),
        switchMap((value) => {
          this.data = value;
          return value;
        }),
        catchError((err) => {
          const { error } = err;
          this.loading = false;
          this.snackbar.open({
            message: error.message || `Pro Number not found.`,
            status: 'error',
            matConfig: {
              duration: 7000,
            },
          });
          return throwError(err);
        })
      )
      .subscribe(() => {
        this.showInfo = true;
        this.loading = false;
      });
  }

  clear() {
    this.pro_lookupForm.reset();
    this.data = [];
    this.showInfo = false;
  }
}
