import {Injectable} from '@angular/core';
import {AbstractControl, ValidatorFn, Validators} from '@angular/forms';

@Injectable()
export class ValidatorHelper {

  static maxLength(length: number, message: string): ValidatorFn {
    return (control: AbstractControl) => {
      return Validators.maxLength(length)(control) ? {maxLength: message} : null;
    };
  }

}
