import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { XpoSnackBar } from '@xpo-ltl/ngx-ltl-core/snack-bar';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Observable, of, throwError } from 'rxjs';
import { ColDef, ColumnApi, GridApi, GridOptions, GridReadyEvent, ValueFormatterParams } from 'ag-grid-community';
import { DeleteRendererComponent } from '../shared/components/action-grid/delete-renderer/delete-renderer.component';
import { XpoAgGridColumns } from '@xpo-ltl/ngx-ltl-grid';
import { MatDrawer, MatDrawerContainer } from '@angular/material/sidenav';
import { CustomerApiService, CustomerMatch, MatchCustomerRqst } from '@xpo-ltl-2.0/sdk-customer';
import { StatesService } from '../shared/services/states/states.service';
import { CountryState } from '../shared/models/country-state';
import { MatSelectChange } from '@angular/material/select';
import { ValidatorHelper } from '../shared/validators';
import { catchError, distinctUntilChanged, find, map, mergeMap, switchMap } from 'rxjs/operators';
import {
  Address,
  CustomerMatchRequestTypeCd,
  CustomerStatusCd,
  ListInfo,
  PartyReviewCustomerTypeCd,
  ShipmentDetailCd,
  ShipmentPartyMatchActionCd,
  User,
} from '@xpo-ltl/sdk-common';
import {
  AssignItemRqst,
  DeleteItemsQuery,
  GetItemPath,
  GetItemResp,
  GetNextUnassignedItemFromQueueByNamePath,
  GetNextUnassignedItemFromQueueByNameQuery,
  GetNextUnassignedItemFromQueueResp,
  GetShipmentQuery,
  Shipment,
  ShipmentApiService,
  UnassignItemRqst,
  UpdateItemStatusRqst,
  UpdatePartyMatchReviewRqst,
} from '@xpo-ltl-2.0/sdk-shipment';
import { UnmatchedCustomer } from '../shared/models/unmatched-customer.model';
import { HasUnsavedChanges } from '../shared/guards/unsaved-changes.guard';
import { XpoLtlConditioningService } from '../shared/services/conditioning-service';
import {
  GetHostSicDetailsPath,
  GetZoneAndSatelliteBySicPath,
  GetZoneAndSatelliteBySicQuery,
  LocationApiService,
} from '@xpo-ltl-2.0/sdk-location';

export enum FunctionCodeLabel {
  Corporate = 'Corporate',
  Bill_To = 'Bill-To',
  BillTo = 'BillTo',
  PickupAndDelivery = 'Pickup & Delivery',
  PickupDelivery = 'PickupDelivery',
  Shipper = 'Shipper',
}

@Component({
  selector: 'app-cis-review-queue',
  templateUrl: './cis-review-queue.component.html',
  styleUrls: ['./cis-review-queue.component.scss'],
  providers: [LocationApiService],
})
export class CisReviewQueueComponent implements OnInit, AfterViewInit, HasUnsavedChanges {
  @ViewChild('matDrawerTray') matDrawerTray!: MatDrawer;
  trayApiGrid?: GridApi;
  private trayGridColumnApi?: ColumnApi;
  trayBtnClear: boolean = false;
  private currentUser: User | undefined;
  unMatchedCustomer!: UnmatchedCustomer;
  loading: boolean = false;
  trayLoading: boolean = false;

  constructor(
    private customerApiService: CustomerApiService,
    private statesService: StatesService,
    private fb: FormBuilder,
    private snackbar: XpoSnackBar,
    private locationApiService: LocationApiService,
    private shipmentApiService: ShipmentApiService,
    private conditionService: XpoLtlConditioningService
  ) {
    this.customerApiService.loggedInUser().subscribe((userData) => {
      this.currentUser = userData;
      this.cisReviewQueueForm.get('review')?.setValue(`${userData.givenName || ''} ${userData.lastName || ''}`);
    });
  }

  hasUnsavedChanges(): boolean {
    return !!this.unMatchedCustomer;
  }

  private beforeUnloadHandler = (event) => {
    event.preventDefault();
    event.returnValue = true;
  };

  cisReviewQueueForm = new FormGroup({
    sicCode: new FormControl('', [Validators.minLength(3), Validators.pattern('')]),
    review: new FormControl(''),
    count: new FormControl(''),
  });

  trayForm = this.fb.group({
    madCode: [null, [this.madCodeValidator]],
    addresses: this.fb.group({
      name1: [null, [ValidatorHelper.maxLength(30, 'Must contain less than or 30 characters.')]],
      name2: [null, [ValidatorHelper.maxLength(30, 'Must contain less than or 30 characters.')]],
      address: [null, [ValidatorHelper.maxLength(30, 'Must contain less than or 30 characters.')]],
      cityName: [null, [ValidatorHelper.maxLength(20, 'Must contain less than or 20 characters.')]],
      stateCd: [],
      zipCd: [null, [ValidatorHelper.maxLength(6, 'Must contain less than or 6 characters.')]],
      countryCd: ['US'],
    }),
  });

  madCodeValidator(control: AbstractControl): ValidationErrors | null {
    return control.value
      ? control.value.length >= 5 && control.value.length <= 11
        ? null
        : { invalidMadCode: 'Must enter between 5-11 characters.' }
      : null;
  }

  states$?: Observable<CountryState[]>;

  countryList = ['US', 'MX', 'CA'];

  ngOnInit(): void {
    this.conditionService.tabSwitched$.subscribe((isProLookup) => {
      if (isProLookup) {
        this.handleProLookupSwitched();
      }
    });
    this.states$ = this.statesService.getStates('US');
    const sicCode = this.cisReviewQueueForm.get('sicCode');
    this.shipmentApiService.loggedInUser().subscribe((user) => {
      sicCode.setValue(user.sicCode);
    });
    sicCode.valueChanges.pipe(distinctUntilChanged()).subscribe((value) => sicCode.patchValue(value.toUpperCase()));
    const madCode = this.trayForm.get('madCode');
    const addresses = this.trayForm.get('addresses');
    madCode?.valueChanges.pipe(distinctUntilChanged()).subscribe((value) => {
      if (value && value.length > 0) {
        madCode.patchValue(madCode.value.toUpperCase(), { emitEvent: false });
        addresses?.reset({
          countryCd: 'US',
        });
        // addresses?.disable();
      } else if (!value) {
        // addresses?.enable();
      }
    });
    addresses?.valueChanges.pipe(distinctUntilChanged()).subscribe((value) => {
      if (value.name1 || value.address) {
        madCode?.reset();
        // madCode?.disable();
      } else if (!value.name1 && !value.address) {
        // madCode?.enable();
      }
    });
  }

  ngAfterViewInit() {
    this.matDrawerTray.closedStart.subscribe(() => {
      //   Todo
      // clean data
    });
  }

  trayData$: Observable<Array<CustomerMatch>> = of([]);

  trayGridOptions: GridOptions = {
    postSort: () => {
      this.trayApiGrid?.refreshCells();
    },
    rowSelection: 'single',
    defaultColDef: {
      sortable: true,
      floatingFilter: false,
      filter: false,
      autoHeight: true,
      floatingFilterComponentParams: { suppressFilterButton: true },
    },
    frameworkComponents: [],
  };

  frameworkComponents = {
    deleteRendererComponent: DeleteRendererComponent,
  };

  trayColumnDefs: ColDef[] = [
    {
      ...XpoAgGridColumns.SelectionWithSelectAll,
      pinned: 'left',
      lockVisible: true,
      maxWidth: 40,
    },
    {
      headerName: 'Account',
      field: 'madCd',
      minWidth: 200,
      suppressMenu: true,
      sortable: false,
      pinned: 'left',
      lockVisible: true,
    },
    {
      headerName: 'Type',
      field: 'customerTypeCd',
      minWidth: 200,
      maxWidth: 250,
      suppressMenu: true,
      valueFormatter: (params) => {
        return this.typeFormat(params);
      },
      sortable: false,
      pinned: 'left',
      lockVisible: true,
    },
    {
      headerName: 'Name/Name2',
      field: 'name1',
      suppressMenu: true,
      minWidth: 350,
      valueFormatter: this.nameFormat,
      sortable: false,
      resizable: true,
    },
    {
      headerName: 'Address',
      field: 'addressLine1',
      minWidth: 450,
      suppressMenu: true,
      valueFormatter: this.addressFormat,
      sortable: false,
    },
  ];

  showInfo: boolean = false;

  onFirstDataRendered(params) {
    const allColumnIds = [];
    this.trayGridColumnApi.getAllColumns().forEach(function (column) {
      allColumnIds.push(column.getColId());
    });
    this.trayGridColumnApi.autoSizeColumns(allColumnIds);
  }

  trayOnGridReady(event: GridReadyEvent) {
    this.trayApiGrid = event.api;
    this.trayGridColumnApi = event.columnApi;
  }

  openTray(event: any, standardTray: MatDrawerContainer) {
    standardTray.open();
    this.trayData$ = of([]);
    this.trayForm.reset();
    this.trayForm.enable();
    this.trayBtnClear = false;
    this.trayForm.get('addresses').get('countryCd').setValue('US');
  }

  findUnmatched(): void {
    this.loading = true;
    this.findUnmatched$().subscribe((data) => {
      this.loading = false;
      this.unMatchedCustomer = data;
      this.showInfo = true;
      window.addEventListener('beforeunload', this.beforeUnloadHandler);
      this.cisReviewQueueForm.disable();
    });
    this.setQueueCount(this.cisReviewQueueForm.get('sicCode').value);
  }

  findUnmatched$(): Observable<UnmatchedCustomer> {
    const queueName = this.cisReviewQueueForm.get('sicCode').value;
    const unMatchedCustomer = new UnmatchedCustomer();
    const pathParams = new GetNextUnassignedItemFromQueueByNamePath();
    pathParams.queueName = queueName;
    const queryParams = new GetNextUnassignedItemFromQueueByNameQuery();
    queryParams.unassignedItemStatusCds = ['NEW', 'ASSIGNED'];
    queryParams.assigneeEmployeeId = this.currentUser.employeeId;

    return this.shipmentApiService.getNextUnassignedItemFromQueueByName(pathParams, queryParams).pipe(
      map((queue: GetNextUnassignedItemFromQueueResp) => {
        const itemId = queue.nextItem.itemInstId;
        if (!itemId) {
          throw `Unable to Find Unmatched Customers from ${pathParams.queueName}.`;
        }
        unMatchedCustomer.itemId = itemId;
        unMatchedCustomer.proNbr = queue.nextItem.applicationInstId;
        unMatchedCustomer.sic = queue.nextItem.queueName;
        return unMatchedCustomer;
      }),
      switchMap((unmatchedCustomer) => {
        const request = new AssignItemRqst();
        request.itemInstId = unmatchedCustomer.itemId;
        request.assigneeEmployeeId = this.currentUser.employeeId;
        return this.shipmentApiService.assignItem(request).pipe(map((value) => unmatchedCustomer));
      }),
      switchMap((unmatchedCustomer) => {
        const getItemPathParams = new GetItemPath();
        getItemPathParams.itemInstId = unmatchedCustomer.itemId;
        return this.shipmentApiService.getItem(getItemPathParams).pipe(
          map((data: GetItemResp) => {
            unMatchedCustomer.determineCustomerType(data.item.applicationKey1);
            unMatchedCustomer.debtorInfo = data.item.applicationKey2;
            return unMatchedCustomer;
          })
        );
      }),
      switchMap((unmatchedCustomer) => {
        const getShipmentQueryParams = new GetShipmentQuery();
        getShipmentQueryParams.proNbr = unmatchedCustomer.proNbr;
        getShipmentQueryParams.shipmentDetailCd = [ShipmentDetailCd.SHIPMENT_PARTIES];
        return this.shipmentApiService.getShipment(getShipmentQueryParams).pipe(
          map((resp) => {
            unmatchedCustomer.determineCustomerInfo(resp.asMatchedParty);
            unmatchedCustomer.billStatCd = resp.shipment.billStatusCd;
            unmatchedCustomer.movementStatCd = resp.shipment.movementStatusCd;
            return unmatchedCustomer;
          })
        );
      }),
      switchMap((unmatchedCustomer) => {
        if (
          unmatchedCustomer.customerInfo &&
          !unmatchedCustomer.customerInfo.asMatchedMadCd &&
          !unmatchedCustomer.customerInfo.cisCustNbr
        ) {
          return of(unmatchedCustomer);
        } else {
          const deleteRqst = new DeleteItemsQuery();
          deleteRqst.itemInstIds = [unmatchedCustomer.itemId];
          return this.shipmentApiService.deleteItems(deleteRqst).pipe(
            mergeMap(() => {
              this.setQueueCount(this.cisReviewQueueForm.get('sicCode').value);
              return this.findUnmatched$();
            })
          );
        }
      }),
      catchError((err) => {
        this.loading = false;
        window.removeEventListener('beforeunload', this.beforeUnloadHandler);
        this.snackbar.open({
          message: `Unable to Find Unmatched Customers from ${queueName}.`,
          status: 'error',
          matConfig: {
            duration: 7000,
          },
        });
        this.unMatchedCustomer = null;
        this.cisReviewQueueForm.enable();
        return throwError(err);
      })
    );
  }

  clear() {
    this.cisReviewQueueForm.reset({
      review: `${this.currentUser?.givenName || ''} ${this.currentUser?.lastName || ''}`,
      sicCode: ``,
      count: '',
    });
    this.showInfo = false;
    this.cisReviewQueueForm.enable();
  }

  preventSearch(e: Event): void {
    e.stopImmediatePropagation();
  }

  getStates(event: MatSelectChange): void {
    this.states$ = this.statesService.getStates(event.source.value);
  }

  getErrorMessage(key: string, parentKey?: string): string {
    let tooltipText = '';
    let control: AbstractControl | null | undefined;
    if (parentKey) {
      control = this.trayForm.get(parentKey)?.get(key);
    } else {
      control = this.trayForm.get(key);
    }
    if (control?.errors) {
      Object.keys(control.errors).forEach((error) => {
        tooltipText += control?.errors?.[error] + '\n';
      });
    }

    return tooltipText;
  }

  nameFormat(params: ValueFormatterParams): string {
    return params.data?.name2 && params.data.name2 !== 'null'
      ? `${params.data?.name1}, ${params.data?.name2}`
      : `${params.data?.name1}`;
  }

  typeFormat(params: ValueFormatterParams): string {
    const matchResultTypeCd = `/${params.data.matchResultTypeCd}` || '';
    switch (params.value) {
      case FunctionCodeLabel.BillTo:
        return `${FunctionCodeLabel.Bill_To}${matchResultTypeCd}`;

      case FunctionCodeLabel.Corporate:
        return `${FunctionCodeLabel.Corporate}${matchResultTypeCd}`;

      case FunctionCodeLabel.PickupDelivery:
      case FunctionCodeLabel.PickupAndDelivery:
      case FunctionCodeLabel.Shipper:
        return `${FunctionCodeLabel.PickupAndDelivery}${matchResultTypeCd}`;
      default:
        return params.value;
    }
  }

  scoreFormat(params: ValueFormatterParams): string {
    return Math.round(params.value).toString();
  }

  addressFormat(params: ValueFormatterParams): string {
    return `${params.data?.addressLine1}, ${params.data?.cityName}, ${params.data?.stateCd} ${params.data?.postalCd}, ${params.data?.countryCd}`;
  }

  searchCustomer(): void {
    if (this.trayForm.valid) {
      this.trayLoading = true;
      const request = new MatchCustomerRqst();
      request.listInfo = new ListInfo();
      request.listInfo.numberOfRows = 100;
      request.listInfo.startAt = 0;
      request.customerTypeCds = [this.unMatchedCustomer.getCustomerTypeCd()];
      request.customerStatusCds = [CustomerStatusCd.ACTIVE];

      if (this.trayForm.value.madCode) {
        request.accuracyThreshold = 50;
        request.requestType = CustomerMatchRequestTypeCd.MAD_CODE;
        request.madCode = this.trayForm.value.madCode.toUpperCase();
      } else {
        request.accuracyThreshold = 80;
        request.requestType = CustomerMatchRequestTypeCd.PARTIAL_DETAILED;
        const searchNameAndAddress = new Address();
        searchNameAndAddress.name = this.trayForm.value.addresses.name1;
        searchNameAndAddress.careOfName = this.trayForm.value.addresses.name2;
        searchNameAndAddress.addressLine1 = this.trayForm.value.addresses.address;
        searchNameAndAddress.cityName = this.trayForm.value.addresses.cityName;
        searchNameAndAddress.stateCd = this.trayForm.value.addresses.stateCd;
        searchNameAndAddress.postalCd = this.trayForm.value.addresses.zipCd;
        searchNameAndAddress.countryCd = this.trayForm.value.addresses.countryCd;
        request.customerNameAndAddress = searchNameAndAddress;
      }

      this.customerApiService
        .matchCustomer(request)
        .pipe(
          map((res) => {
            this.trayBtnClear = true;
            this.trayLoading = false;
            return res.matchedCustomers || [];
          }),
          catchError((err) => {
            this.trayLoading = false;
            return throwError(err);
          })
        )
        .subscribe((value) => (this.trayData$ = of(value)));
    } else {
      this.trayLoading = false;
      this.trayBtnClear = false;
      this.snackbar.open({
        message: 'Invalid form, please check Mad Code, Name1 or Address is required.',
        status: 'error',
        matConfig: {
          duration: 5000,
        },
      });
    }
  }

  trayClear() {
    this.trayForm.reset();
    this.trayForm.enable();
    this.trayBtnClear = false;
  }

  confirmCustomer(standardTray: MatDrawerContainer, selectedRows: any[] | undefined) {
    const customerInfo: CustomerMatch = selectedRows[0];
    this.unMatchedCustomer.customerInfo.name1 = customerInfo.name1;
    this.unMatchedCustomer.customerInfo.name2 = customerInfo.name2;
    this.unMatchedCustomer.customerInfo.address = customerInfo.addressLine1;
    this.unMatchedCustomer.customerInfo.city = customerInfo.cityName;
    this.unMatchedCustomer.customerInfo.stateCd = customerInfo.stateCd;
    this.unMatchedCustomer.customerInfo.countryCd = customerInfo.countryCd;
    this.unMatchedCustomer.customerInfo.zip6 = customerInfo.postalCd;
    this.unMatchedCustomer.customerInfo.asMatchedMadCd = customerInfo.madCd;
    this.unMatchedCustomer.hostSic = customerInfo.hostSicCd;
    standardTray.close();
  }

  private handleProLookupSwitched(): void {
    if (this.unMatchedCustomer) {
      const unassignRqst = new UnassignItemRqst();
      unassignRqst.itemInstId = this.unMatchedCustomer.itemId;
      unassignRqst.context = new Shipment();
      this.showInfo = false;
      this.loading = true;
      this.shipmentApiService.unassignItem(unassignRqst).subscribe((data) => {
        this.loading = false;
        window.removeEventListener('beforeunload', this.beforeUnloadHandler);
        this.unMatchedCustomer = null;
      });
    }
  }

  unassignUser(standardTray: MatDrawerContainer): void {
    const unassignRqst = new UnassignItemRqst();
    unassignRqst.itemInstId = this.unMatchedCustomer.itemId;
    unassignRqst.context = new Shipment();
    this.showInfo = false;
    this.loading = true;
    this.shipmentApiService.unassignItem(unassignRqst).subscribe((data) => {
      this.loading = false;
      if (standardTray) {
        standardTray.close();
      }
      window.removeEventListener('beforeunload', this.beforeUnloadHandler);
      this.unMatchedCustomer = null;
      this.cisReviewQueueForm.enable();
      this.snackbar.success('The item has been unassigned. You can now exit the app.');
    });
  }

  private validateHostSic(): Observable<boolean> {
    if (
      this.unMatchedCustomer.customerType === PartyReviewCustomerTypeCd.INBOUND ||
      this.unMatchedCustomer.customerType === PartyReviewCustomerTypeCd.OUTBOUND
    ) {
      return of(true);
    }
    if (this.unMatchedCustomer.sic === this.unMatchedCustomer.hostSic) {
      return of(true);
    }
    const pathParams = new GetHostSicDetailsPath();
    pathParams.sicCd = this.unMatchedCustomer.sic;
    return this.locationApiService.getHostSicDetails(pathParams).pipe(
      switchMap((resp) => {
        const path = new GetZoneAndSatelliteBySicPath();
        const query = new GetZoneAndSatelliteBySicQuery();
        path.sicCd = resp.hostSicCd;
        query.zoneInd = true;
        query.satelliteInd = true;
        return this.locationApiService.getZoneAndSatelliteBySic(path, query);
      }),
      find((resp) => resp.zoneSatelliteInfo.some((info) => info.locationSic === this.unMatchedCustomer.hostSic)),
      map((result) => {
        if (!result) {
          this.snackbar.open({
            message: 'MAD code is outside of SIC',
            status: 'error',
            matConfig: {
              duration: 7000,
            },
          });
          this.loading = false;
          throw new Error('MAD code is outside of SIC');
        }
        return true;
      })
    );
  }
  handleUnMatchedCustomer(actionCd: ShipmentPartyMatchActionCd) {
    this.loading = true;
    new Observable<void>((observer) => observer.next())
      .pipe(
        switchMap(() => {
          if (actionCd === ShipmentPartyMatchActionCd.MATCH) {
            return this.validateHostSic();
          } else {
            return of(true);
          }
        }),
        switchMap(() => {
          if (actionCd === ShipmentPartyMatchActionCd.MATCH) {
            const request = new UpdatePartyMatchReviewRqst();
            request.sicCd = this.unMatchedCustomer.sic;
            request.employeeId = this.currentUser.employeeId;
            request.proNbr = this.unMatchedCustomer.proNbr;
            request.madCode = this.unMatchedCustomer.customerInfo.asMatchedMadCd;
            request.shipmentPartyMatchActionCd = actionCd;
            request.partyReviewCustomerTypeCd = this.unMatchedCustomer.customerType;
            return this.shipmentApiService.updatePartyMatchReview(request).pipe(
              catchError((err) => {
                const { error } = err;
                const moreInfo = error.moreInfo && error.moreInfo[0] ? error.moreInfo[0] : error.message;
                this.loading = false;
                this.snackbar.open({
                  message: moreInfo.message || 'Unable to process the Customer',
                  status: 'error',
                  matConfig: {
                    duration: 7000,
                  },
                });
                return throwError(err);
              })
            );
          }
          return new Observable<void>((observer) => observer.next());
        }),
        switchMap(() => {
          this.showInfo = false;
          if (actionCd === ShipmentPartyMatchActionCd.SKIP) {
            const unassignRqst = new UnassignItemRqst();
            unassignRqst.itemInstId = this.unMatchedCustomer.itemId;
            unassignRqst.context = new Shipment();
            return this.shipmentApiService.unassignItem(unassignRqst);
          }
          const requestUpdateItemStatus = new UpdateItemStatusRqst();
          requestUpdateItemStatus.itemInstId = this.unMatchedCustomer.itemId;
          requestUpdateItemStatus.itemStatus = actionCd;
          requestUpdateItemStatus.context = new Shipment();
          return this.shipmentApiService.updateItemStatus(requestUpdateItemStatus);
        }),
        switchMap(() => {
          switch (actionCd) {
            case ShipmentPartyMatchActionCd.MATCH:
              return this.snackbar.success('Matched Customer Processed').afterDismissed();
            case ShipmentPartyMatchActionCd.NO_MATCH:
              return this.snackbar.success('Unmatched Customer Processed').afterDismissed();
            case ShipmentPartyMatchActionCd.SKIP:
              return new Observable<void>((observer) => observer.next());
            default:
              return new Observable<void>((observer) => observer.next());
          }
        }),
        switchMap(() => {
          this.setQueueCount(this.cisReviewQueueForm.get('sicCode').value);
          return this.findUnmatched$();
        })
      )
      .subscribe((data) => {
        this.loading = false;
        this.unMatchedCustomer = data;
        this.showInfo = true;
      });
  }

  matSearchAfterExpand() {
    this.trayBtnClear = false;
  }

  setQueueCount(queueName: string): void {
    this.shipmentApiService
      .listQueueItemCounts()
      .pipe(
        map((value) => {
          const items = value.queueItemCounts;
          return items.find((item) => item.queueName === queueName);
        }),
        map((queueNameItems) => {
          const itemCountsByStatus = queueNameItems.itemCountsByStatus;
          return itemCountsByStatus.filter(
            (itemByStatus) => itemByStatus.currentStatusCd === 'NEW' || itemByStatus.currentStatusCd === 'ASSIGNED'
          );
        }),
        map((items) => {
          return items.reduce((accumulator, currentValue) => {
            return accumulator + currentValue.totalCountByStatus;
          }, 0);
        })
      )
      .subscribe((value) => this.cisReviewQueueForm.get('count').setValue(value));
  }
}
