<xpo-shell [drawerClosedByDefault]="true">
  <xpo-header-app-name>Edge</xpo-header-app-name>
  <xpo-header-sub-app-name>Customer Matching</xpo-header-sub-app-name>
  <xpo-header-navigation>
    <button (click)="notifyTabSwitched(false)" routerLink="cis-review-queue" routerLinkActive="xpo-selected">
      CIS Review Queue
    </button>
    <button (click)="notifyTabSwitched(true)" routerLink="pro-lookup" routerLinkActive="xpo-selected">
      PRO Lookup
    </button>
  </xpo-header-navigation>
  <xpo-header-actions>
    <xpo-auth-user-profile></xpo-auth-user-profile>
  </xpo-header-actions>
  <div class="container">
    <div class="main">
      <router-outlet></router-outlet>
    </div>
    <xpo-footer class="footer" xpoFooterSticky [version]="build"></xpo-footer>
  </div>
</xpo-shell>
