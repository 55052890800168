import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CisReviewQueueComponent } from './cis-review-queue/cis-review-queue.component';
import { XpoAuthenticationGuard } from '@xpo/ngx-auth';
import { ProlookupComponent } from './prolookup/prolookup.component';
import { UnsavedChangesGuard } from './shared/guards/unsaved-changes.guard';

const routes: Routes = [
  {
    path: 'cis-review-queue',
    component: CisReviewQueueComponent,
    canActivate: [XpoAuthenticationGuard],
    canDeactivate: [UnsavedChangesGuard],
  },
  {
    path: 'pro-lookup',
    component: ProlookupComponent,
    canActivate: [XpoAuthenticationGuard],
  },
  { path: '', redirectTo: '/cis-review-queue', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
