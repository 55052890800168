import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { XpoShellModule } from '@xpo-ltl/ngx-ltl-core/shell';
import { XpoFooterModule } from '@xpo-ltl/ngx-ltl-core/footer';
import { XpoButtonModule } from '@xpo-ltl/ngx-ltl-core/button';
import { MatDialogModule } from '@angular/material/dialog';
import { XpoDialogModule } from '@xpo-ltl/ngx-ltl-core/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { XpoSnackBarModule } from '@xpo-ltl/ngx-ltl-core/snack-bar';
import { DeleteRendererComponent } from './components/action-grid/delete-renderer/delete-renderer.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { XpoConfirmDialogModule } from '@xpo-ltl/ngx-ltl-core/confirm-dialog';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { XpoTrayModule } from '@xpo-ltl/ngx-ltl-core/tray';
import { MatTableModule } from '@angular/material/table';
import { XpoSelectModule } from '@xpo-ltl/ngx-ltl-core/select';
import { MatExpansionModule } from '@angular/material/expansion';
import { XpoCardModule } from '@xpo-ltl/ngx-ltl-core/card';
import { MatListModule } from '@angular/material/list';
import { HumanizePipe } from './pipes/humanize/humanize.pipe';
import { XpoBusyLoaderModule } from '@xpo-ltl/ngx-ltl-core/busy-loader';
import { MatRadioModule } from '@angular/material/radio';

@NgModule({
  declarations: [DeleteRendererComponent, HumanizePipe],
  imports: [
    CommonModule,
    XpoShellModule,
    XpoFooterModule,
    XpoButtonModule,
    MatDialogModule,
    XpoDialogModule,
    MatFormFieldModule,
    MatInputModule,
    XpoSnackBarModule,
    MatTooltipModule,
    XpoConfirmDialogModule,
    MatSelectModule,
    MatSidenavModule,
    XpoTrayModule,
    MatTableModule,
    XpoSelectModule,
    MatExpansionModule,
    XpoCardModule,
    MatListModule,
    XpoBusyLoaderModule,
    MatRadioModule,
  ],
  exports: [
    XpoShellModule,
    XpoFooterModule,
    XpoButtonModule,
    MatDialogModule,
    XpoDialogModule,
    MatFormFieldModule,
    MatInputModule,
    XpoSnackBarModule,
    DeleteRendererComponent,
    MatTooltipModule,
    XpoConfirmDialogModule,
    MatSelectModule,
    MatSidenavModule,
    XpoTrayModule,
    MatTableModule,
    XpoSelectModule,
    XpoCardModule,
    MatExpansionModule,
    MatListModule,
    HumanizePipe,
    XpoBusyLoaderModule,
    MatRadioModule,
  ],
})
export class SharedModule {}
