<xpo-card>
  <xpo-card-content>
    <form [formGroup]="pro_lookupForm">
      <div class="container-form">
        <div class="container-row">
          <mat-form-field floatLabel="always">
            <mat-label>PRO NUMBER:</mat-label>
            <input matInput placeholder="Enter Pro #" formControlName="pro_number" required type="string" />
          </mat-form-field>
        </div>
      </div>
    </form>
    <xpo-button-group>
      <button mat-flat-button xpoButton [disabled]="pro_lookupForm.invalid || showInfo" (click)="onSearch()">
        Submit
      </button>
      <button mat-stroked-button xpoButton (click)="clear()">Clear</button>
    </xpo-button-group>
  </xpo-card-content>
</xpo-card>
<br />
<div class="busyContainer" *ngIf="loading">
  <div [style.width.px]="100">
    <xpo-busy-loader></xpo-busy-loader>
  </div>
</div>
<app-pro-number-lookup-detail [data]="data" *ngIf="showInfo"> </app-pro-number-lookup-detail>
