import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { XpoAgGridModule } from '@xpo-ltl/ngx-ltl-grid';
import { AgGridModule } from 'ag-grid-angular';
import { AngularFireModule } from '@angular/fire';
import { environment } from '../environments/environment';
import { ReactiveFormsModule } from '@angular/forms';
import { BatchFormDialog } from './batch-schedule/batch-form-dialog/batch-form-dialog.component';
import { BatchScheduleComponent } from './batch-schedule/batch-schedule.component';
import { CisReviewQueueComponent } from './cis-review-queue/cis-review-queue.component';
import { XPO_AUTH_CONFIG, XpoAuthConfig } from '@xpo/ngx-auth';
import { XpoLtlAuthConfigLoaderService, XpoLtlAuthModule } from '@xpo-ltl/ngx-auth';
import { XpoLtlAuthUiModule } from '@xpo-ltl/ngx-auth-ui';
import { CONFIG_MANAGER_SERVICE_CONFIGURATION, ConfigManagerModule } from '@xpo-ltl/config-manager';
import { XpoFormFieldModule } from '@xpo-ltl/ngx-ltl-core/form-field';
import { SdkCustomerModule } from '@xpo-ltl-2.0/sdk-customer';
import { UnmatchedCustomerComponent } from './cis-review-queue/unmatched-customer/unmatched-customer.component';
import { SdkShipmentModule } from '@xpo-ltl-2.0/sdk-shipment';
import { ProlookupComponent } from './prolookup/prolookup.component';
import { ProNumberLookupDetailComponent } from './prolookup/pro-number-lookup-detail/pro-number-lookup-detail.component';
import { SdkHumanResourceModule } from '@xpo-ltl/sdk-humanresource';

export function authConfigFactory(configService: XpoLtlAuthConfigLoaderService): XpoAuthConfig {
  configService.initSettings('./assets/config.json');

  return new XpoAuthConfig({
    appCode: configService.appCode,
    scopes: configService.scopes,
    isProd: configService.isProd,
    applicationRootUri: configService.appRootUri,
  });
}

@NgModule({
  declarations: [
    AppComponent,
    BatchScheduleComponent,
    BatchFormDialog,
    CisReviewQueueComponent,
    UnmatchedCustomerComponent,
    ProlookupComponent,
    ProNumberLookupDetailComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    XpoAgGridModule,
    AgGridModule.withComponents([]),
    AngularFireModule.initializeApp(environment.firebase),
    XpoLtlAuthModule.forRoot({
      provide: XPO_AUTH_CONFIG,
      useFactory: authConfigFactory,
      deps: [XpoLtlAuthConfigLoaderService],
    }),
    XpoLtlAuthUiModule,
    ConfigManagerModule,
    XpoFormFieldModule,
    SdkCustomerModule,
    SdkShipmentModule,
    SdkHumanResourceModule,
  ],
  providers: [
    {
      provide: CONFIG_MANAGER_SERVICE_CONFIGURATION,
      useValue: { configFileUrl: './assets/config.json' },
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
