<div class="grid-container">
  <H2>Customer</H2>
  <div *ngIf="data.length == 0">No data found</div>
  <xpo-ag-grid *ngIf="data.length > 0">
    <ag-grid-angular
      (gridReady)="trayOnGridReady($event)"
      [columnDefs]="trayColumnDefs"
      [gridOptions]="trayGridOptions"
      [rowHeight]="25"
      [headerHeight]="30"
      (firstDataRendered)="onFirstDataRendered($event)"
      [alwaysShowHorizontalScroll]="true"
      rowSelection="single"
      [rowData]="data"
    >
    </ag-grid-angular>
  </xpo-ag-grid>
</div>
