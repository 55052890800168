import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, publishReplay, refCount } from 'rxjs/operators';
import {CountryState} from "../../models/country-state";
import {StateGroup} from "../../models/state-group";

@Injectable({
  providedIn: 'root',
})
export class StatesService {
  private statesListUrl = './assets/states/states.json';
  private stateGroupValue$?: Observable<StateGroup[]>;

  constructor(private http: HttpClient) {}

  getStates(country?: string): Observable<CountryState[]> {
    if (!this.stateGroupValue$) {
      this.stateGroupValue$ = this.http.get<StateGroup[]>(this.statesListUrl).pipe(
        map((res) => res),
        publishReplay(1), // this tells Rx to cache the latest emitted value
        refCount()
      );
    }

    if (!country) {
      return (
        this.stateGroupValue$.pipe(map((group) => group.map((x) => x.states).reduce((acc, val) => acc.concat(val), []))) ||
        of(new Array<CountryState>())
      );
    } else {
      return this.stateGroupValue$.pipe(
        map((group) => {
          if (group) {
            const c = group.find((g) => g.country === country);
            if (c) {
              return c.states;
            }
          }
          return new Array<CountryState>();
        })
      );
    }
  }

  getAllStates(): Observable<Map<string, CountryState[]>> {
    if (!this.stateGroupValue$) {
      this.stateGroupValue$ = this.http.get<StateGroup[]>(this.statesListUrl).pipe(
        map((res) => res),
        publishReplay(1), // this tells Rx to cache the latest emitted value
        refCount()
      );
    }

    return this.stateGroupValue$.pipe(
      map((res) => {
        const statesMap = new Map<string, CountryState[]>();
        res.map((group) => {
          statesMap.set(group.country, group.states);
        });

        return statesMap;
      })
    );
  }
}
