import {Component} from '@angular/core';
import {ICellRendererAngularComp} from "ag-grid-angular";
import {ICellRendererParams} from "ag-grid-community";
import {DistGenBatchSchedule} from "../../../models/dist-gen-batch-schedule";
import {XpoConfirmDialog, XpoConfirmDialogConfig} from "@xpo-ltl/ngx-ltl-core/confirm-dialog";

@Component({
  selector: 'app-delete-renderer',
  template: `
    <button
      *ngIf="data"
      tabindex="-1"
      matTooltip="Delete"
      matTooltipPosition="above"
      (click)="openConfirm()"
      mat-icon-button
    >
      <mat-icon>delete</mat-icon>
    </button>
  `,
  styleUrls: ['./delete-renderer.component.scss'],
})
export class DeleteRendererComponent implements ICellRendererAngularComp {
  params: { data: DistGenBatchSchedule; deleteAction: any; } | undefined;
  data: DistGenBatchSchedule | undefined;

  constructor(private confirmDialog: XpoConfirmDialog) {
  }

  agInit(params: ICellRendererParams | any): void {
    this.data = params.data;
    this.params = params || null;
  }

  refresh(params: ICellRendererParams): boolean {
    return false;
  }

  openConfirm(): void {
    // Set configuration for the dialog
    const confirmConfig: XpoConfirmDialogConfig = {
      confirmButtonText: 'Delete',
      rejectButtonText: 'Cancel',
      icon: 'warning',
    };

    this.confirmDialog
      .confirm(
        `You are about to delete the following record`,
        'Do you want to delete?',
        confirmConfig
      )
      .subscribe((result) => {
        if (result) {
          this.params?.deleteAction(this.data?.idKey);
        }
      });
  }

}
