<xpo-card>
  <xpo-card-header>
    <xpo-card-title>
      <h1>UNMATCHED CUSTOMER</h1>
    </xpo-card-title>
  </xpo-card-header>

  <xpo-card-content>
    <div class="parent">
      <div class="div1">
        <h2>PRO NUMBER</h2>
        <span matTooltip="This PRO is Not Rated." [matTooltipDisabled]="isProNbrRated()">
          <a
            [style]="isProNbrRated() ? 'cursor: pointer' : 'pointer-events: none'"
            (click)="onClickPronumber(unmatchedCustomer.proNbr)"
          >
            <span>{{ unmatchedCustomer.proNbr }}</span>
          </a>
        </span>
      </div>
      <div class="div2">
        <h2>TYPE</h2>
        <span>{{ unmatchedCustomer.customerTypeDescr }}</span>
      </div>
      <div class="div3">
        <h2>DEBTOR</h2>
        <span>{{ unmatchedCustomer.debtorInfo }}</span>
      </div>
      <div class="div4">
        <h2>NAME</h2>
        <div>{{ unmatchedCustomer.customerInfo?.name1 }}</div>
        <div>{{ unmatchedCustomer.customerInfo?.name2 }}</div>
      </div>
      <div class="div5">
        <h2>MAD CODE</h2>
        <span>{{ unmatchedCustomer.customerInfo?.asMatchedMadCd }}</span>
      </div>
      <div class="div6">
        <h2>ADDRESS</h2>
        <div>
          {{ unmatchedCustomer.customerInfo?.address | lowercase | humanize }},
          {{ unmatchedCustomer.customerInfo?.city | lowercase | humanize }},
          {{ unmatchedCustomer.customerInfo?.stateCd }} {{ unmatchedCustomer.customerInfo?.zip6
          }}{{ !!unmatchedCustomer.customerInfo?.zip4RestUs ? '-' + unmatchedCustomer.customerInfo?.zip4RestUs : '' }}
        </div>
        <button class="btn-customer-search" mat-flat-button xpoButton (click)="customerSearch()">
          Customer Search
        </button>
      </div>
    </div>
  </xpo-card-content>
</xpo-card>
<br />
<xpo-button-group>
  <button (click)="handleUnMatchedCustomer(ShipmentPartyMatchActionCd.MATCH)" mat-flat-button xpoButton>Match</button>
  <button (click)="handleUnMatchedCustomer(ShipmentPartyMatchActionCd.SKIP)" mat-stroked-button xpoButton>Skip</button>
  <button (click)="handleUnMatchedCustomer(ShipmentPartyMatchActionCd.NO_MATCH)" mat-stroked-button xpoButton>
    No Match
  </button>
  <button (click)="unassignUser()" mat-stroked-button xpoButton>Exit</button>
</xpo-button-group>
