import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UnmatchedCustomer } from '../../shared/models/unmatched-customer.model';
import { BillStatusCd, ShipmentPartyMatchActionCd } from '@xpo-ltl/sdk-common';
import { ConfigManagerProperties } from '../../shared/enums/config-manager-properties.enum';
import { ConfigManagerService } from '@xpo-ltl/config-manager';

@Component({
  selector: 'app-unmatched-customer',
  templateUrl: './unmatched-customer.component.html',
  styleUrls: ['./unmatched-customer.component.scss'],
})
export class UnmatchedCustomerComponent {
  ShipmentPartyMatchActionCd = ShipmentPartyMatchActionCd;
  url = '';
  isProd = false;
  region = 'https://app.ltl.xpo.com/appjs/invoice-auditor/invoicing/';

  @Input() unmatchedCustomer!: UnmatchedCustomer;
  @Output() onSearchCustomer = new EventEmitter<any>();
  @Output() onHandleUnMatchedCustomer = new EventEmitter<ShipmentPartyMatchActionCd>();
  @Output() onUnassign = new EventEmitter<any>();

  constructor(private configManagerService: ConfigManagerService) {
    this.region = this.configManagerService.getSetting<string>(ConfigManagerProperties.region);
    this.isProd = this.configManagerService.getSetting<boolean>(ConfigManagerProperties.production);
  }

  customerSearch() {
    const data = {};
    this.onSearchCustomer.emit(data);
  }

  handleUnMatchedCustomer(shipmentPartyMatchActionCd: ShipmentPartyMatchActionCd) {
    this.onHandleUnMatchedCustomer.emit(shipmentPartyMatchActionCd);
  }

  unassignUser(): void {
    this.onUnassign.emit();
  }

  isProNbrRated(): boolean {
    return this.unmatchedCustomer.billStatCd === BillStatusCd.RATED;
  }

  onClickPronumber(proNbr: string) {
    const url = `https://${
      !this.isProd ? this.region.toLowerCase() + '-' : ''
    }app.ltl.xpo.com/appjs/invoice-auditor/invoicing/${proNbr}`;
    window.open(url, '_blank');
  }
}
