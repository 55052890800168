import { Component, Input, OnInit } from '@angular/core';
import { ColDef, ColumnApi, GridApi, GridOptions, GridReadyEvent, ValueFormatterParams } from 'ag-grid-community';
import { UnmatchedCustomer } from '../../shared/models/unmatched-customer.model';
import * as moment from 'moment';

@Component({
  selector: 'app-pro-number-lookup-detail',
  templateUrl: './pro-number-lookup-detail.component.html',
  styleUrls: ['./pro-number-lookup-detail.component.scss'],
})
export class ProNumberLookupDetailComponent implements OnInit {
  private trayGridColumnApi: ColumnApi;
  private trayApiGrid: GridApi;

  constructor() {}

  @Input() data: Array<UnmatchedCustomer>;
  trayColumnDefs: ColDef[] = [
    {
      headerName: 'PRO NUMBER',
      field: 'proNbr',
      minWidth: 100,
      suppressMenu: true,
      sortable: false,
      pinned: 'left',
      lockVisible: true,
    },
    {
      headerName: 'SIC CODE',
      field: 'queueName',
      minWidth: 80,
      suppressMenu: true,
      sortable: false,
    },
    {
      headerName: 'MAD CODE',
      field: 'customerInfo.asMatchedMadCd',
      valueFormatter: (params) => {
        return params.value || '-//-';
      },
      suppressMenu: true,
      minWidth: 100,
      sortable: false,
    },
    {
      headerName: 'TYPE',
      field: 'customerTypeDescr',
      suppressMenu: true,
      minWidth: 100,
      sortable: false,
    },
    {
      headerName: 'DEBTOR',
      field: 'debtorInfo',
      suppressMenu: true,
      minWidth: 100,
      sortable: false,
    },
    {
      headerName: 'NAME',
      field: 'customerInfo',
      suppressMenu: true,
      valueFormatter: this.nameFormat,
      minWidth: 350,
      sortable: false,
    },
    {
      headerName: 'ADDRESS',
      field: 'customerInfo',
      suppressMenu: true,
      valueFormatter: this.addressFormat,
      minWidth: 480,
      sortable: false,
    },
    {
      headerName: 'STATUS',
      field: 'status',
      suppressMenu: true,
      minWidth: 80,
      sortable: false,
    },
    {
      headerName: 'ASSIGNED',
      field: 'assigneeEmployeeName',
      suppressMenu: true,
      minWidth: 100,
      sortable: false,
    },
    {
      headerName: 'UPDATE ON',
      field: 'lockExpirationDateTime',
      suppressMenu: true,
      valueFormatter: (params) => {
        return moment(params.value).format('MM/DD/YYYY HH:mm:ss');
      },
      minWidth: 100,
      sortable: false,
    },
  ];
  trayGridOptions: GridOptions = {
    postSort: () => {
      this.trayApiGrid?.refreshCells();
    },
    rowSelection: 'single',
    defaultColDef: {
      sortable: true,
      floatingFilter: false,
      filter: false,
      autoHeight: true,
      floatingFilterComponentParams: { suppressFilterButton: true },
    },
    frameworkComponents: [],
  };

  ngOnInit(): void {}

  trayOnGridReady(event: GridReadyEvent) {
    this.trayApiGrid = event.api;
    this.trayGridColumnApi = event.columnApi;
  }

  onFirstDataRendered(params) {
    const allColumnIds = [];
    this.trayGridColumnApi.getAllColumns().forEach(function (column) {
      allColumnIds.push(column.getColId());
    });
    this.trayGridColumnApi.autoSizeColumns(allColumnIds);
  }

  nameFormat(params: ValueFormatterParams): string {
    if (!params.value) return '-//-';
    return params.value?.name2 && params.value.name2 !== 'null'
      ? `${params.value?.name1}, ${params.value?.name2}`
      : `${params.value?.name1}`;
  }

  addressFormat(params: ValueFormatterParams): string {
    if (!params.value) return '-//-';
    return `${params.value?.address}, ${params.value?.city}, ${params.value?.stateCd} , ${params.value?.countryCd}`;
  }
}
