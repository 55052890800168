import { AsMatchedParty } from '@xpo-ltl-2.0/sdk-shipment';
import {
  BillStatusCd,
  CustomerFunctionCd,
  CustomerTypeCd,
  MatchedPartyTypeCd,
  MovementStatusCd,
  PartyReviewCustomerTypeCd,
} from '@xpo-ltl/sdk-common';

export class UnmatchedCustomer {
  itemId: number;
  proNbr: string;
  billStatCd: BillStatusCd;
  movementStatCd: MovementStatusCd;
  sic: string;
  hostSic: string;
  customerType: PartyReviewCustomerTypeCd;
  debtorInfo: string;
  customerInfo: AsMatchedParty;
  customerTypeDescr: string;
  status: string;
  assigneeEmployeeId: string;
  assigneeEmployeeName: string;
  lockExpirationDateTime: Date;
  queueName: string;
  constructor() {}

  determineCustomerType(typeString: string) {
    if (!typeString) return;
    switch (typeString.toUpperCase()) {
      case 'C':
        this.customerType = PartyReviewCustomerTypeCd.CONSIGNEE;
        this.customerTypeDescr = PartyReviewCustomerTypeCd.CONSIGNEE;
        break;
      case 'S':
        this.customerType = PartyReviewCustomerTypeCd.SHIPPER;
        this.customerTypeDescr = PartyReviewCustomerTypeCd.SHIPPER;
        break;
      case 'I':
        this.customerType = PartyReviewCustomerTypeCd.INBOUND;
        this.customerTypeDescr = `BILL-TO`;
        break;
      case 'O':
        this.customerType = PartyReviewCustomerTypeCd.OUTBOUND;
        this.customerTypeDescr = `BILL-TO`;
        break;
    }
  }

  determineCustomerInfo(asMatchedParties: AsMatchedParty[]) {
    this.customerInfo = asMatchedParties.find((matchParty) => {
      let type: PartyReviewCustomerTypeCd;
      switch (matchParty.typeCd) {
        case MatchedPartyTypeCd.CONS:
          type = PartyReviewCustomerTypeCd.CONSIGNEE;
          break;
        case MatchedPartyTypeCd.SHPR:
          type = PartyReviewCustomerTypeCd.SHIPPER;
          break;
        case MatchedPartyTypeCd.BILL_TO_INB:
          type = PartyReviewCustomerTypeCd.INBOUND;
          break;
        case MatchedPartyTypeCd.BILL_TO_OTB:
          type = PartyReviewCustomerTypeCd.OUTBOUND;
          break;
      }
      return type === this.customerType;
    });
  }

  getCustomerFunctionCd(): CustomerFunctionCd {
    switch (this.customerType) {
      case PartyReviewCustomerTypeCd.SHIPPER:
      case PartyReviewCustomerTypeCd.CONSIGNEE:
        return CustomerFunctionCd.PICKUP_OR_DELIVERY;
      case PartyReviewCustomerTypeCd.INBOUND:
      case PartyReviewCustomerTypeCd.OUTBOUND:
        return CustomerFunctionCd.BILL_TO;
    }
  }

  getCustomerTypeCd(): CustomerTypeCd {
    switch (this.customerType) {
      case PartyReviewCustomerTypeCd.SHIPPER:
      case PartyReviewCustomerTypeCd.CONSIGNEE:
        return CustomerTypeCd.PICKUP_DELIVERY;
      case PartyReviewCustomerTypeCd.INBOUND:
      case PartyReviewCustomerTypeCd.OUTBOUND:
        return CustomerTypeCd.BILL_TO;
    }
  }
}
